<script>
export default {
  head: {
    title: 'Onboarding & Tutoriels',
  },
};
</script>

<template>
  <section class="columns is-multiline">
    <div class="column is-12">
      <h2 class="title is-4">
        Découvrez de nouvelles possibilités
      </h2>
    </div>

    <div class="column is-3">
      <div class="box is-info is-flex is-align-items-flex-end pb-0 h-full">
        <figure class="image flex-none w-full">
          <img
            src="@app/assets/img/ai_assistant/super.png"
            alt="Votre assistant Copywriter personnel"
          >
        </figure>
      </div>
    </div>
    <div class="column is-9">
      <article
        class="box has-background-facebook has-text-white content is-medium"
      >
        <h2 class="title is-4 has-text-white">
          Je vais vous aider à mieux vendre !
        </h2>
        <p>
          Pour vendre, il faut convaincre le prospect
          que c'est LA bonne formation pour lui.
        </p>
        <p class="has-text-weight-bold">
          Pour cela, il vous faut un titre accrocheur,
          ainsi qu'une page de vente efficace qui parlera à votre cible.
        </p>
        <p>
          Rédigez des pages de vente qui convertissent
          grâce à moi, votre assistant personnel, votre copywriter
          à Intelligence Artificielle.
        </p>
        <p>
          <b-button
            class="is-white py-6"
            tag="router-link"
            :to="{name: 'ai_assistant'}"
          >
            Utiliser mes services dès maintenant
          </b-button>
        </p>
      </article>
    </div>
    <div class="column is-12">
      <h3 class="title is-4">
        Comment utiliser mes services ?
      </h3>
      <div class="image is-16by9">
        <iframe class="pabsolute-0 w-full h-full" src="https://www.youtube.com/embed/Hh7oY3wYZHs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
      </div>
    </div>
    <div class="column is-12">
      <article class="box is-warning content is-medium">
        <h2 class="title is-4">
          Un copywriter freelance, ça coûte cher !
        </h2>
        <p>
          <strong>
            Vous n'en trouverez d'ailleurs pas à moins de 250 &euro; / jour.
          </strong>
          <br>
          Voyez par vous-même en
          <a
            class="tdecoration-underline"
            href="https://www.malt.fr/s?q=copywriter&location=En+t%C3%A9l%C3%A9travail+(France)&remoteAllowed=true&as=t&p=1&exp=INTERMEDIATE"
          >cliquant ici</a>.
        </p>
        <p>
          Tout le monde n'a pas les moyens de s'offrir les services d'un copywriter...
          <br>
          Et tout le monde n'est pas expert en rédaction de page de vente !
        </p>
        <p class="has-text-weight-bold">
          Mais aujourd'hui, vous avez un copywriter
          spécialiste en vente de formation
          à un prix IMBATTABLE.
        </p>
        <p>
          Comment ? Grâce à la puissance de l'Intelligence Artificielle (IA).
        </p>
      </article>
    </div>
  </section>
</template>
